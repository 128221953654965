<template>
  <div id="note-books-table">
    <!-- <b-table-simple hover :show-empty="true">
      <b-thead>
        <b-tr>
          <b-th v-for="(field, key) in defaultFields" :key="key">{{
            field.label
          }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="data in tblDatas" :key="data.billNumber">
          <b-td v-if="defaultFields['billNumber']"
            ><router-link
              :to="{
                path: '/bills',
                query: { id: data.billNumber },
              }"
              target="_blank"
              >{{ data.billNumber }}
            </router-link></b-td
          >
          <b-td v-if="defaultFields['selected']">
            <label
              class="checkbox checkbox-single"
              v-bind:class="`checkbox-light-primary`"
            >
              <input
                type="checkbox"
                v-model="data.selected"
                @change="onChange(data)"
              />
              <span></span> </label
          ></b-td>
          <b-td v-if="defaultFields['totalAmount']">
            <span>{{ convertPrice(data.totalAmount) }}</span></b-td
          >
          <b-td v-if="defaultFields['totalAmoutCash']"
            ><span>{{
              convertPrice(
                (data.payments || []).reduce((result, item) => {
                  if (item.paymentType === 1) {
                    result += item.paymentAmount;
                  }
                  return result;
                }, 0),
              )
            }}</span></b-td
          >
          <b-td v-if="defaultFields['saleName']"
            ><span>{{ data.saleName }}</span></b-td
          >
        </b-tr>
      </b-tbody> -->
    <!-- <template #empty>
        <div class="text-center">
          <span class="d-block">
            <inline-svg src="media/svg/empty-icons/EmptyInbox.svg" />
          </span>
          <span class="d-block text-dark">Không có dữ liệu</span>
        </div>
      </template>
      <template #foot()="row">
        {{ row.label }}
      </template> -->
    <!-- </b-table-simple> -->
    <b-table
      hover
      :items="tblDatas"
      :fields="Object.values(tblFields)"
      :show-empty="true"
    >
      <template #empty>
        <div class="text-center">
          <span class="d-block">
            <inline-svg src="media/svg/empty-icons/EmptyInbox.svg" />
          </span>
          <span class="d-block text-dark">Không có dữ liệu</span>
        </div>
      </template>
      <template #cell(billNumber)="row">
        <router-link
          :to="{
            path: '/bills',
            query: { id: row.item.billNumber },
          }"
          target="_blank"
          >{{ row.item.billNumber }}
        </router-link>
      </template>
      <template #cell(selected)="row">
        <label
          class="checkbox checkbox-single"
          v-bind:class="`checkbox-light-primary`"
        >
          <input
            type="checkbox"
            v-model="row.item.selected"
            @change="onChange(row.item)"
          />
          <span></span>
        </label>
      </template>
      <template #cell(totalAmount)="row">
        <span>{{ convertPrice(row.item.totalAmount) }}</span>
      </template>
    </b-table>
  </div>
</template>
    
<script>
import { convertPrice } from '@/utils/common';

export default {
  props: {
    tblDatas: {
      type: Array,
    },
    tab: {
      type: String,
    },
  },
  data() {
    return {
      selectedBills: {},
      defaultFields: {
        billNumber: {
          key: 'billNumber',
          label: 'Mã hoá đơn',
        },
        totalAmoutCash: {
          key: 'totalAmoutCash',
          label: 'Tổng tiền mặt',
        },
        totalAmount: {
          key: 'totalAmount',
          label: 'Tổng tiền',
        },
        saleName: {
          key: 'saleName',
          label: 'Tên nhân viên',
        },
      },
    };
  },
  components: {},
  computed: {
    tblFields() {
      switch (this.tab) {
        case 'sent-revenue': {
          const newField = {
            ...this.defaultFields,
            selected: {
              key: 'selected',
              label: '',
            },
          };
          this.$forceUpdate();
          return newField;
        }
        case 'verify-revenue': {
          const newField = {
            ...this.defaultFields,
            verifyRevenueDate: {
              key: 'verifyRevenueDate',
              label: 'Ngày xác nhận',
            },
          };
          this.$forceUpdate();
          return newField;
        }
      }
      const newField = {
        ...this.defaultFields,
      };
      this.$forceUpdate();
      return newField;
    },
  },
  mounted() {
    console.log('trung');
  },
  created() {},
  methods: {
    convertPrice,
    onChange(item) {
      this.$emit('selected-bill', item);
    },
  },
};
</script>

<style lang="scss" scoped>
#note-books-table ::v-deep {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
  }
  .table th {
    background-color: #f4f6f9;
  }

  table tbody {
    box-shadow: 0 0 0 1px #f4f6f9;
    border-radius: 0 0 0.42rem 0.42rem;
  }

  table th:first-child {
    box-shadow: 0 0 0 1px #f4f6f9;
    border-radius: 0.42rem 0 0 0;
  }

  table th:last-child {
    box-shadow: 0 0 0 1px #f4f6f9;
    border-radius: 0 0.42rem 0 0;
  }
}
</style>
 